.logo {
  width: 165px;
  position: fixed;
  top: 12px;
  right: 120px;
  z-index: 99;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.fixed-header {
  height: 10px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.large-header {
  height: 20px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
}

.scrolled-logo {
  opacity: 0;
}

.hidden-logo {
  display: none;
}

nav {
  display: none;
  width: 100%;
  height: 10px;
  background: black;
  position: fixed;
  z-index: 10;
  transition: all 0.05s linear;
}

@media (max-width: 900px), (max-width: 1050px) and (orientation: portrait) {
  .logo {
    width: 150px;
    position: fixed;
    top: 20px;
    right: 100px;
  }
}

@media only screen and (max-width: 830px) {
  .logo {
    width: 100px;
    top: 12px;
    right: 100px;
  }
}

@media only screen and (max-width: 730px) {
  .logo {
    width: 100px;
    top: 7px;
    right: 80px;
  }
}

@media only screen and (max-width: 830px) and (orientation: landscape) {
  .logo {
    width: 100px;
    top: 7px;
    right: 100px;
  }
}
