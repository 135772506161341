/*AVAILABILITY*/
 .availability {
     height: 100vh;
     grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
     -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
     grid-template-rows: 20vh 80vh;
     -ms-grid-rows: 20vh 120vh;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .availability {
    height: 140vh;
  }
}

 .item-g4 {
     padding-top: 4vh;
     padding-left: 7%;
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 6;
     -ms-grid-column-span: 5;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 2;
     -ms-grid-row-span: 1;
}
 .stack-parent {
     font-family: "Roboto", sans-serif;
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 6;
     -ms-grid-column-span: 5;
     grid-row-start: 2;
     -ms-grid-row: 2;
     grid-row-end: 3;
     -ms-grid-row-span: 1;
     border-bottom: 10px solid black;
}
 .stacking-grid {
     padding: 3%;
     width: calc(50vw + 355px);
     margin: auto;
     font-family: "Roboto", sans-serif;
     display: grid;
     display: -ms-grid;
     grid-template-columns: 10vw 10vw 15vw 15vw 355px;
     -ms-grid-columns: 10vw 10vw 15vw 15vw 355px;
     grid-template-rows: 65px 50px 51px 51px 46px 58.5px 51px 51px 60px 76px 34px 34px auto;
     -ms-grid-rows: 65px 50px 51px 51px 46px 58.5px 51px 51px 60px 76px 34px 34px 1fr;
}
 .stacking-grid span {
     position: absolute;
     bottom: 0;
     margin-bottom: 5px;
}
 .stacking-grid a {
     text-decoration: none;
}
 .stacking-image-grid {
     pointer-events: none;
     grid-column-start: 5;
    -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 13;
     -ms-grid-row-span: 12;
     z-index: 2;
}
 .stacking-image-grid img{
     width: 357px;
}
 @media screen and (max-width: 1200px) , screen and (max-height: 1050px) {
     .stacking-image-grid img{
         width: 285.5px;
    }
     .stacking-grid {
         padding-left: 5%;
         padding-right: 5%;
         margin: auto;
         grid-template-columns: 10vw 10vw 15vw 15vw 285.5px;
         -ms-grid-columns: 10vw 10vw 15vw 15vw 285.5px;
         grid-template-rows: 52px 40px 41px 40.5px 40px 43.5px 41px 41px 48px 59px 27.5px 27px;
         -ms-grid-rows: 52px 40px 41px 40.5px 40px 43.5px 41px 41px 48px 59px 27.5px 27px;
    }
}
/* mobile portrait */
 @media (max-width: 900px) and (orientation: landscape), (max-width: 1050px) and (orientation: portrait) {
     .availability {
         height: auto;
         grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
         -ms-grid-columns: 7vw 15vw 10vw 10vw auto 100px;
         grid-template-rows: 20vh auto auto auto auto;
         -ms-grid-rows: 20vh 1fr 1fr 1fr 1fr;
    }
     .availability .right {
         display: none;
    }
     .stacking-grid {
         display: none;
    }
     .stack-floor {
         height: auto;
         padding-bottom: 6vh;
         display: grid;
         display: -ms-grid;
         grid-column-start: 1;
         -ms-grid-column: 1;
         -ms-grid-column: 1;
         grid-column-end: 7;
         -ms-grid-column-span: 6;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         -ms-grid-row-span: 1;
         border-bottom: 10px solid black;
         grid-template-columns: 1fr;
    }
     .item-g4 {
         padding: 3vh;
         padding-left: 7vh;
    }
     .button-parent-grid {
         margin: auto;
         margin-top: 3%;
         width: 200px;
         height: 2rem;
         display: flex;
         flex-direction: row;
         text-align: bottom;
    }
}
 @media only screen and (max-width: 830px) and (orientation: landscape) {
     .availability {
         height: auto;
         grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
         -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
         grid-template-rows: 40vh auto auto auto auto;
         -ms-grid-rows: 40vh 1fr 1fr 1fr 1fr;
    }
}
/*MAIN GRID*/
 .stack {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
 .a1 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column: 1;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 2;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a2 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 2;
     -ms-grid-row: 2;
     grid-row-end: 3;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a3 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 3;
     -ms-grid-row: 3;
     grid-row-end: 4;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a4 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 4;
     -ms-grid-row: 4;
     grid-row-end: 5;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a5 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 5;
     -ms-grid-row: 5;
     grid-row-end: 6;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a6 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 6;
     -ms-grid-row: 6;
     grid-row-end: 7;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a7 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 7;
     -ms-grid-row: 7;
     grid-row-end: 8;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a8 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 8;
     -ms-grid-row: 8;
     grid-row-end: 9;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a9 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 9;
     -ms-grid-row: 9;
     grid-row-end: 10;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a10 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 10;
     -ms-grid-row: 10;
     grid-row-end: 11;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a11 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 11;
     -ms-grid-row: 11;
     grid-row-end: 12;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .a12 {
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 2;
     -ms-grid-column-span: 1;
     grid-row-start: 12;
     -ms-grid-row: 12;
     grid-row-end: 13;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b1 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 2;
     border-bottom: 1px solid black;
     position: relative;
     -ms-grid-column-span: 1;
     -ms-grid-row-span: 1;
}
 .b2 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 2;
     -ms-grid-row: 2;
     grid-row-end: 3;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b3 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 3;
     -ms-grid-row: 3;
     grid-row-end: 4;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     -ms-grid-row-span: 1;
}
 .b4 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 4;
     -ms-grid-row: 4;
     grid-row-end: 5;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b5 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 5;
     -ms-grid-row: 5;
     grid-row-end: 6;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b6 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 6;
     -ms-grid-row: 6;
     grid-row-end: 7;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b7 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 7;
     -ms-grid-row: 7;
     grid-row-end: 8;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b8 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 8;
     -ms-grid-row: 8;
     grid-row-end: 9;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b9 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 9;
     -ms-grid-row: 9;
     grid-row-end: 10;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b10 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 10;
     -ms-grid-row: 10;
     grid-row-end: 11;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b11 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 11;
     -ms-grid-row: 11;
     grid-row-end: 12;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .b12 {
     grid-column-start: 2;
     -ms-grid-column: 2;
     grid-column-end: 3;
     -ms-grid-column-span: 1;
     grid-row-start: 12;
     -ms-grid-row: 12;
     grid-row-end: 13;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c1 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 2;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c2 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 2;
     -ms-grid-row: 2;
     grid-row-end: 3;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c3 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 3;
     -ms-grid-row: 3;
     grid-row-end: 4;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c4 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 4;
     -ms-grid-row: 4;
     grid-row-end: 5;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c5 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 5;
     -ms-grid-row: 5;
     grid-row-end: 6;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c6 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 6;
     -ms-grid-row: 6;
     grid-row-end: 7;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c7 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 7;
     -ms-grid-row: 7;
     grid-row-end: 8;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c8 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 8;
     -ms-grid-row: 8;
     grid-row-end: 9;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c9 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 9;
     -ms-grid-row: 9;
     grid-row-end: 10;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c10 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 10;
     -ms-grid-row: 10;
     grid-row-end: 11;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c11 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 11;
     -ms-grid-row: 11;
     grid-row-end: 12;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .c12 {
     grid-column-start: 3;
     -ms-grid-column: 3;
     grid-column-end: 4;
     -ms-grid-column-span: 1;
     grid-row-start: 12;
     -ms-grid-row: 12;
     grid-row-end: 13;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
}
 .d1 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 2;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d2 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 2;
     -ms-grid-row: 2;
     grid-row-end: 3;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d3 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 3;
     -ms-grid-row: 3;
     grid-row-end: 4;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d4 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 4;
     -ms-grid-row: 4;
     grid-row-end: 5;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d5 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 5;
     -ms-grid-row: 5;
     grid-row-end: 6;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d6 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 6;
     -ms-grid-row: 6;
     grid-row-end: 7;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d7 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 7;
     -ms-grid-row: 7;
     grid-row-end: 8;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d8 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 8;
     -ms-grid-row: 8;
     grid-row-end: 9;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d9 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 9;
     -ms-grid-row: 9;
     grid-row-end: 10;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d10 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 10;
     -ms-grid-row: 10;
     grid-row-end: 11;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d11 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 11;
     -ms-grid-row: 11;
     grid-row-end: 12;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .d12 {
     grid-column-start: 4;
     -ms-grid-column: 4;
     grid-column-end: 5;
     -ms-grid-column-span: 1;
     grid-row-start: 12;
     -ms-grid-row: 12;
     grid-row-end: 13;
     -ms-grid-row-span: 1;
     border-bottom: 1px solid black;
     position: relative;
     margin-right: 30px;
}
 .e1 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 1;
     -ms-grid-row: 1;
     grid-row-end: 2;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e2 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 2;
     -ms-grid-row: 2;
     grid-row-end: 3;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e3 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 3;
     -ms-grid-row: 3;
     grid-row-end: 4;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e4 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 4;
     -ms-grid-row: 4;
     grid-row-end: 5;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e5 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 5;
     -ms-grid-row: 5;
     grid-row-end: 6;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e6 {
     grid-column-start: 5;
     -ms-grid-column: 5;
      grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 6;
     -ms-grid-row: 6;
     grid-row-end: 7;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e7 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 7;
     -ms-grid-row: 7;
     grid-row-end: 8;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e8 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 8;
     -ms-grid-row: 8;
     grid-row-end: 9;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e9 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 9;
     -ms-grid-row: 9;
     grid-row-end: 10;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e10 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     -ms-grid-column-span: 1;
     grid-column-end: 6;
     grid-row-start: 10;
     -ms-grid-row: 10;
     grid-row-end: 11;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e11 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 11;
     -ms-grid-row: 11;
     grid-row-end: 12;
     -ms-grid-row-span: 1;
     position: relative;
}
 .e12 {
     grid-column-start: 5;
     -ms-grid-column: 5;
     grid-column-end: 6;
     -ms-grid-column-span: 1;
     grid-row-start: 12;
     -ms-grid-row: 12;
     grid-row-end: 13;
     -ms-grid-row-span: 1;
     position: relative;
}
 .stack-buttons {
     display: flex;
     grid-row-start: 13;
     -ms-grid-row: 13;
     grid-row-end: 14;
     -ms-grid-row-span: 1;
     grid-column-start: 1;
     -ms-grid-column: 1;
     grid-column-end: 6;
     -ms-grid-column-span: 5;
     flex-direction: row;
     margin: 2% 0;
}
 .stack-buttons div {
     width: 20vw;
}
/*MOBILE SPECIFIC*/
 .mobile-stack {
     display: none;
     padding-bottom: 3rem;
}
 .mobile-stack span {
     pointer: cursor
}
 .mobile-stack-2 {
     display: none;
     padding-bottom: 3rem;
}
 .mobile-stack-details {
     display: none;
     padding-bottom: 3vh;
}
 .mobile-stack-arrows {
     display: none;
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mobile-stack-floor {
    display: none;
  }
}

/* mobile portrait */
 @media (max-width: 900px) and (orientation: landscape), (max-width: 1050px) and (orientation: portrait) {

     .mobile-stack {
         transform: translateX(0);
         padding-left: 3%;
         padding-right: 3%;
         font-family: "Roboto", sans-serif;
         display: grid;
         display: -ms-grid;
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 7;
         -ms-grid-column-span: 6;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         -ms-grid-row-span: 1;
         grid-template-columns: 200px auto auto;
         -ms-grid-columns: 200px 1fr 1fr;
         grid-template-rows: 61.85px 49.12px 49.12px 49.12px 49.12px 63.59px 63.59px 63.59px 63.59px 17.82px 85.96px 20px 20px;
         -ms-grid-rows: 61.85px 49.12px 49.12px 49.12px 49.12px 63.59px 63.59px 63.59px 63.59px 17.82px 85.96px 20px 20px;
    }
     .mobile-stack span {
         position: absolute;
         bottom: 0;
    }
     .mobile-stack-2 {
         width: 100vw;
         padding-left: 3%;
         padding-right: 3%;
         transform: translateX(calc(100%));
         display: grid;
         display: -ms-grid;
         font-family: "Roboto", sans-serif;
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 7;
         -ms-grid-span: 6;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         -ms-grid-span: 1;
         grid-template-columns: 20% 20% 30% auto;
         -ms-grid-columns: 20% 20% 30% 1fr;
         grid-template-rows: 72.67px 58.14px 58.14px 58.14px 58.14px 72.67px 72.67px 72.67px 72.67px 22.36px 58.14px;
         -ms-grid-rows: 72.67px 58.14px 58.14px 58.14px 58.14px 72.67px 72.67px 72.67px 72.67px 22.36px 58.14px;
    }
     .mobile-stack-2 span {
         position: absolute;
         bottom: 0;
    }
     .mobile-stack-2 div {
         position: relative;
         border-bottom: 1px solid black;
    }
     .stack-parent {
         border-bottom: none;
    }
     .ms1a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         -ms-grid-column-span: 1;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 2;
         -ms-grid-row-span: 1;
    }
     .ms1b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         -ms-grid-column-span: 1;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 2;
         -ms-grid-column-span: 1;
    }
     .ms1c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 2;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms1d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 2;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms2a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms2b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms2c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms2d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms3a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms3b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms3c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms3d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms4a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms4b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms4c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms4d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms5a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms5b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms5c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms5d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms6a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 6;
         -ms-grid-row: 6;
         grid-row-end: 7;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms6b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 6;
         -ms-grid-row: 6;
         grid-row-end: 7;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms6c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 6;
         -ms-grid-row: 6;
         grid-row-end: 7;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms6d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 6;
         -ms-grid-row: 6;
         grid-row-end: 7;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms7a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 7;
         -ms-grid-row: 7;
         grid-row-end: 8;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms7b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 7;
         -ms-grid-row: 7;
         grid-row-end: 8;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms7c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 7;
         -ms-grid-row: 7;
         grid-row-end: 8;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms7d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 7;
         -ms-grid-row: 7;
         grid-row-end: 8;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms8a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 8;
         -ms-grid-row: 8;
         grid-row-end: 9;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms8b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 8;
         -ms-grid-row: 8;
         grid-row-end: 9;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms8c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 8;
         -ms-grid-row: 8;
         grid-row-end: 9;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms8d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 8;
         -ms-grid-row: 8;
         grid-row-end: 9;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms9a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 9;
         -ms-grid-row: 9;
         grid-row-end: 10;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms9b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 9;
         -ms-grid-row: 9;
         grid-row-end: 10;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms9c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 9;
         -ms-grid-row: 9;
         grid-row-end: 10;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms9d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 9;
         -ms-grid-row: 9;
         grid-row-end: 10;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms10a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 10;
         -ms-grid-row: 10;
         grid-row-end: 11;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms10b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 10;
         -ms-grid-row: 10;
         grid-row-end: 11;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms10c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 10;
         -ms-grid-row: 10;
         grid-row-end: 11;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms10d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 10;
         -ms-grid-row: 10;
         grid-row-end: 11;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms11a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 11;
         -ms-grid-row: 11;
         grid-row-end: 12;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms11b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 11;
         -ms-grid-row: 11;
         grid-row-end: 12;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms11c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 11;
         -ms-grid-row: 11;
         grid-row-end: 12;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms11d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 11;
         -ms-grid-row: 11;
         grid-row-end: 12;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms12a {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         grid-row-start: 12;
         -ms-grid-row: 12;
         grid-row-end: 13;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms12b {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 12;
         -ms-grid-row: 12;
         grid-row-end: 13;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms12c {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 12;
         -ms-grid-row: 12;
         grid-row-end: 13;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .ms12d {
         grid-column-start: 4;
         -ms-grid-column: 4;
         grid-column-end: 5;
         grid-row-start: 12;
         -ms-grid-row: 12;
         grid-row-end: 13;
         -ms-grid-row-span: 1;
         -ms-grid-column-span: 1;
    }
     .mobile-stack-arrows {
         display: flex;
         width: 100vw;
         flex-direction: row;
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 7;
         -ms-grid-column-span: 6;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         -ms-grid-row-span: 1;

    }
     .mobile-stack-arrows div {
         position: relative;
         height: 7vh;
         width: 50%;
         cursor: pointer;
    }
     .left-div svg {
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         padding-left: 5%;
    }
     .right-div svg {
         position: absolute;
         top: 0;
         bottom: 0;
         right: 0;
         padding-right: 5%;
    }
     .to-next {
         transform: translateX(100%);
         transition-duration: 1s;
    }
     .to-screen {
         display: grid;
         display: -ms-grid;
         transform: translateX(0);
         transition-duration: 1s;
    }
     .to-left {
         transform: translateX(-100%);
         transition-duration: 1s;
    }
     .mobile-stack-details {
         width: 100vw;
         display: block;
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 7;
         -ms-grid-column-span: 6;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         -ms-grid-row-span: 1;
    }
     .mobile-stack-details-parent {
         opacity: 0;
         display: flex;
         flex-direction: row;
         width: 90vw;
         font-family: "Roboto", sans-serif;
         margin: auto;
         -webkit-transition: all 0.3s ease;
         -moz-transition: all 0.3s ease;
         -o-transition: all 0.3s ease;
         transition: all 0.3s ease;
    }
     .mobile-stack-details-parent div {
         width: 30vw;
         border-bottom: 1px solid black;
    }
     .expose {
         opacity: 1;
    }
     .mobile-stack-floor {
         grid-column-start: 1;
         grid-column-end: 7;
         grid-row-start: 5;
         border-bottom: 5px solid black;
         padding-bottom: 3%;
    }

     .mobile-stack-floor div {
         text-align: center;
         width: auto;
         margin: auto;
    }

     .mobile-stack-floor .button-one {
         margin: 1% auto;
    }

}
 @media (max-width: 1010px) {
     .mobile-stack {
         width: 100vw;
         display: grid;
         display: -ms-grid;
         grid-template-columns: 175px auto auto;
         -ms-grid-columns: 175px 1fr 1fr;
         grid-template-rows: 27px 26px 26px 26px 24px 26px 26px 26px 26px 36px 20px 20px;
         -ms-grid-rows: 27px 26px 26px 26px 24px 26px 26px 26px 26px 36px 20px 20px;
    }
     .mobile-stack span {
         position: absolute;
         bottom: 0;
    }
     .mobile-stack-2 {
         grid-template-rows: 27px 26px 26px 26px 24px 26px 26px 26px 26px 36px 20px 20px;
         -ms-grid-rows: 27px 26px 26px 26px 24px 26px 26px 26px 26px 36px 20px 20px;
    }
     .mobile-stack-arrows {
         display: flex;
         width: 100vw;
         flex-direction: row;
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 7;
         -ms-grid-column-span: 6;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         -ms-grid-row-span: 1;
    }
     .mobile-stack-arrows div {
         position: relative;
         height: 20px;
         width: 50%;
         cursor: pointer;
    }
     .left-div svg {
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         padding-left: 5%;
         height: 100%;
         width: auto;
    }
     .right-div svg {
         position: absolute;
         top: 0;
         bottom: 0;
         right: 0;
         padding-right: 5%;
         height: 100%;
         width: auto;
    }
     .mob-stack-img {
         grid-column-start: 1;
         -ms-grid-column: 1;
         grid-column-end: 2;
         -ms-grid-column-span: 1;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 13;
         -ms-grid-row-span: 12;
    }
     .mob-stack-img img {
         width: 100%;
    }
     .mobile-stack div{
         position: relative;
    }
     .ms1 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 2;
         border-bottom: 1px solid black;
    }
     .ms2 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         border-bottom: 1px solid black;
    }
     .ms3 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         border-bottom: 1px solid black;
    }
     .ms4 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         border-bottom: 1px solid black;
    }
     .ms5 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         border-bottom: 1px solid black;
    }
     .ms6 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 6;
         -ms-grid-row: 6;
         grid-row-end: 7;
         border-bottom: 1px solid black;
    }
     .ms7 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 7;
         -ms-grid-row: 7;
         grid-row-end: 8;
         border-bottom: 1px solid black;
    }
     .ms8 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 8;
         -ms-grid-row: 8;
         grid-row-end: 9;
         border-bottom: 1px solid black;
    }
     .ms9 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 9;
         -ms-grid-row: 9;
         grid-row-end: 10;
         border-bottom: 1px solid black;
    }
     .ms10 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 10;
         -ms-grid-row: 10;
         grid-row-end: 11;
         border-bottom: 1px solid black;
    }
     .ms11 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 11;
         -ms-grid-row: 11;
         grid-row-end: 12;
         border-bottom: 1px solid black;
    }
     .ms12 {
         grid-column-start: 2;
         -ms-grid-column: 2;
         grid-column-end: 3;
         grid-row-start: 12;
         -ms-grid-row: 12;
         grid-row-end: 13;
         border-bottom: 1px solid black;
    }
     .ms1r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 1;
         -ms-grid-row: 1;
         grid-row-end: 2;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms2r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 2;
         -ms-grid-row: 2;
         grid-row-end: 3;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms3r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 3;
         -ms-grid-row: 3;
         grid-row-end: 4;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms4r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 4;
         -ms-grid-row: 4;
         grid-row-end: 5;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms5r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 5;
         -ms-grid-row: 5;
         grid-row-end: 6;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms6r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 6;
         -ms-grid-row: 6;
         grid-row-end: 7;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms7r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 7;
         -ms-grid-row: 7;
         grid-row-end: 8;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms8r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 8;
         -ms-grid-row: 8;
         grid-row-end: 9;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms9r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 9;
         -ms-grid-row: 9;
         grid-row-end: 10;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms10r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 10;
         -ms-grid-row: 10;
         grid-row-end: 11;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms11r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 11;
         -ms-grid-row: 11;
         grid-row-end: 12;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
     .ms12r {
         grid-column-start: 3;
         -ms-grid-column: 3;
         grid-column-end: 4;
         grid-row-start: 12;
         -ms-grid-row: 12;
         grid-row-end: 13;
         border-bottom: 1px solid black;
         cursor: pointer;
    }
}
 @media (max-width: 500px) {
     .mobile-stack span {
         font-size: 0.7em;
    }
     .mobile-stack-2 span {
         font-size: 0.7em;
    }
     .mobile-stack-details-parent div {
         font-size: 0.7em;
    }
}
 @media (max-width: 830px) and (orientation: landscape), (max-width: 500px) and (orientation: portrait) {
     .stack-floor {
         border-bottom: 5px solid black;
    }
}
 .open {
     cursor: pointer;
     font-weight: bold;
}
 .open:hover {
     color: gray;
}
 #modal {
     display: none;
     top: 0;
     left: 0;
     background-color: rgba(0,0,0,0.6);
     width: 100%;
     height: 100%;
     position: fixed;
     z-index: 2000;
}
 .button-parent-modal {
     margin-top: 2rem;
     margin-bottom: 2rem;
     width: 140px;
     height: 32px;
     text-align: bottom;
     cursor: pointer;
}
 .display {
     display: block !important;
}
 .modal-content {
     display: flex;
     flex-direction: column;
     position: absolute;
     width: calc(100vw - 100px);
     height: 100%;
     left: 0;
     background-color: white;
     opacity: 1;
     padding: 10px;
     border-top: 10px solid black;
     border-bottom: 10px solid black;
}
 .gallery-mod {
     border: 0;
     padding: 0;
     display: grid;
     grid-template-columns: auto 82vw auto;
     margin: 0 auto;
     padding-bottom: 50px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   .gallery-mod {
    width: 100%;
    display: -ms-flexbox;
    -ms-flex-direction: row;
    flex: 1;
    -ms-flexbox: 1 1 auto;
  }
}

 .gallery-mod div {
     position: relative;
}
 .gallery-modal div {
     position: relative;
}
 .fp-parent {
     height: 80vh;
     width: 80vw;
}
 .fp-image {
     width: 100%;
     height: 100%;
     object-fit: contain;
}
 .floorplan-img {
     width: 100%;
     margin: auto;
}
 .inner-fp {
     background-color: pink;
}
 #x {
     height: auto;
     cursor: pointer;
}
 #x img {
     float: right;
     height: 35px;
     width: auto;
     padding: 5px;
}
 .download {
     position: absolute;
     top: 60px;
     right: 2px;
}
