/*LAYOUT MISC*/

.submit-grid {
  margin-top: 20%;
  margin-left: 3%;
  float: left;
  padding: 3%;
  width: 90%;
  text-align: bottom;
  font-size: 0.9em;
  border-top: 1px solid black;
  font-family: "Roboto", sans-serif;
}

.medium {
  width: 70%;
}

.text-div {
  padding-top: 6%;
  width: 80%;
}

.text-div2 {
  width: 40%;
  margin-bottom: 40%;
}

.valign-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
  }

.vertical-center {
  display: table; width:100%;
}

.vertical-center-full {
  display: table-cell; vertical-align: middle;
}

/* mobile portrait */
@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .text-div2 {
    margin: 0;
    width: 60%;
  }

  .text-div {
    margin: 0;
    width: 100%;
  }

  .submit-grid {
    font-size: 1rem;
  }
}

/*FLEX BOX*/

.parent {
  display: flex;
  flex-direction: row;
  text-align: bottom;
}

.hor-flex-container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.hor-flex-container-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hor-flex-container-3 {
  padding-top: 70%;
  display: flex;
  flex-direction: column;
  height: 90%;
}

.hor-flex-child-2 {
  height: 50%;
}

.hor-flex-child-3 {
  height: 10%;
  margin: 0.5em;
  width: 2px;
  background-color: #d3d3d3;
  align-self: center;
}

.hor-flex-child {
  padding: 2vh;
  /* padding-right: 30%; */
  padding-left: 0;
}

.figure-div {
  /* border-top: 7px solid black; */
  font-size: 1.5em;
  padding-top: 0;
}

.hor-flex-child h3 {
  margin: 3px 0;
}

.gray {
  background-color: #cccccc;
  width: 200px;
}

.bar {
  background-color: black;
  height: 10px;
  width: 0px;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .hor-flex-container-2 {
    display: none;
  }
  .figure-div {
    font-size: 1rem;
  }
}

/*GRID SPECS - AVAILABILITY IN AVAILABILITY.CSS, LOCATION & MAP IN LOCATION.CSS*/

section {
  width: 100vw;
  padding: 0;
  display: grid;
  display: -ms-grid;
  margin: 0;
  background-color: transparent;
  scroll-behavior: smooth;
  overflow: hidden;
}

.standard {
  margin-top: -1px;
  height: 100vh;
  grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
  grid-template-rows: 40vh 30vh 30vh;
  -ms-grid-rows: 40vh 30vh 30vh;
}

.right {
  z-index: 4;
  height: 100%;
  border-left: 10px black solid;
  border-bottom: 0;
  grid-column-start: 6;
  -ms-grid-column: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  grid-row-end: 4;
  -ms-grid-row-span: 3;
}

.right-long {
  z-index: 4;
  height: 100%;
  border-left: 10px black solid;
  border-bottom: 0;
  grid-column-start: 6;
  -ms-grid-column: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 5;
  -ms-grid-row-span: 4;
}

.intro {
  height: calc(100vh + 10px);
  grid-template-columns: 7vw 15vw 10vw 15vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 15vw 1fr 100px;
  -ms-grid-rows: calc(100vh + 10px);
  display: grid;
  display: -ms-grid;
  z-index: 4;
}

.wrapper {
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/Home%40x2.jpg");
  background-size: cover;
  background-position: bottom;
}

.architecture {
  height: 100vh;
  grid-template-columns: 42vw 20vw 10vw 10vw auto 100px;
  -ms-grid-columns: 42vw 20vw 10vw 10vw 1fr 100px;
  grid-template-rows: 30vh 30vh auto;
  -ms-grid-rows: 30vh 30vh 1fr;
  overflow: none;
}

.team {
  height: 100vh;
  grid-template-columns: 11vw 20vw 11vw 25vw auto 100px;
  -ms-grid-columns: 11vw 20vw 11vw 25vw 1fr 100px;
  grid-template-rows: 35vh 30vh auto;
  -ms-grid-rows: 45vh 40vh 1fr;
  overflow: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .team {
    height: 120vh;
  }
}

.facts-and-figures {
  height: 100vh;
  grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
  grid-template-rows: 25vh 75vh;
  -ms-grid-rows: 25vh 115vh;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .facts-and-figures {
    height: 140vh;
  }
}

.retail {
  height: 100vh;
  grid-template-columns: 7vw 15vw 10vw 13vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 13vw 1fr 100px;
  grid-template-rows: 50vh 50vh;
  -ms-grid-rows: 60vh 60vh;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .retail {
    height: 120vh;
  }
}

.office {
  height: 100vh;
  grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
  grid-template-rows: 25vh 75vh;
  -ms-grid-rows: 25vh 75vh;
}

.contact {
  height: 100vh;
  grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
  grid-template-rows: 33vh 33vh 34vh;
  -ms-grid-rows: 33vh 33vh 34vh;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .intro .right {
    display: none;
  }

  .intro {
    height: 70vh;
    grid-template-columns: 10vw 15vw 10vw 15vw auto;
    -ms-grid-columns: 10vw 15vw 10vw 15vw 1fr;
    border-bottom: 10px black solid;
    background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/Home%40x2.png");
    background-size: cover;
    background-position: bottom;
  }

  .standard {
    height: auto;
    grid-template-columns: 100px 15vw 10vw 10vw auto 100px;
    -ms-grid-columns: 100px 15vw 10vw 10vw 1fr 100px;
    grid-template-rows: auto auto auto;
    -ms-grid-rows: 1fr 1fr 1fr;
  }

  .standard .item-c {
    display: none;
  }

  .standard .right {
    border-left: 10px black solid;
    border-bottom: 10px black solid;
  }

  .architecture {
    height: auto;
    grid-template-columns: 40vw auto 40vw;
    -ms-grid-columns: 40vw 1fr 40vw;
    grid-template-rows: auto 300px auto auto 300px 300px;
    -ms-grid-rows: auto 300px 1fr 1fr 300px 300px;
  }

  .facts-and-figures {
    height: auto;
    grid-template-columns: 100px 5vw 10vw 10vw auto 100px;
    -ms-grid-columns: 100px 5vw 10vw 10vw 1fr 100px;
    grid-template-rows: 300px 40vh auto;
    -ms-grid-rows: 300px 40vh 1fr;
  }

  .facts-and-figures .right {
    display: none;
  }
  .office {
    height: auto;
    grid-template-columns: 100px 15vw 10vw 10vw auto 100px;
    -ms-grid-columns: 100px 15vw 10vw 10vw 1fr 100px;
    grid-template-rows: auto auto auto auto auto;
    -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 10px solid black;
  }
  .office .right-long {
    display: none;
  }
  .retail {
    height: auto;
    grid-template-columns: 100px 15vw 10vw 10vw auto 100px;
    -ms-grid-columns: 100px 15vw 10vw 10vw 1fr 100px;
    grid-template-rows: auto 40vh auto 40vh;
    -ms-grid-rows: 1fr 40vh 1fr 40vh;
  }

  .retail .right-long {
    display: none;
  }

  .retail .right {
    display: none;
  }

  .team {
    height: auto;
    grid-template-columns: 20vw 20vw auto 20vw 10vw 10vw;
    -ms-grid-columns: 20vw 20vw 1fr 20vw 10vw 10vw;
    grid-template-rows: auto 300px auto 300px 300px;
    -ms-grid-rows: 1fr 300px 1fr 300px 300px;
    overflow: none;
  }

  .contact .right {
    display: none;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .intro {
    border-bottom: 5px black solid;
    background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/Home-mob.jpg");
  }
  .standard {
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 50px;
  }
  .standard .right {
    border-left: 5px black solid;
    border-bottom: 5px black solid;
  }
  .retail {
    height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 50px;
    grid-template-rows: auto 300px auto 300px;
    -ms-grid-rows: 1fr 300px 1fr 300px;
  }
  .architecture {
    /* grid-template-columns: 50px 15vw 10vw 10vw auto 100px; */
  }
  .facts-and-figures {
    grid-template-columns: 50px 5vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 5vw 10vw 10vw 1fr 50px;
  }
  .office {
    grid-template-columns: 50px 15vw auto 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 1fr 10vw 1fr 50px;
    border-bottom: 5px solid black;
  }
}

@media (max-width: 830px) and (orientation: landscape) {
  .intro .right {
    display: none;
  }
  .intro {
    height: 100vh;
    grid-template-columns: 10vw 15vw 10vw 15vw auto;
    -ms-grid-columns: 10vw 15vw 10vw 15vw 1fr;
    border-bottom: 5px black solid;
    background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/Home-mob.jpg");
    background-size: cover;
  }
  .wrapper {
    display: none;
  }
  .standard {
    height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 50px;
    grid-template-rows: 50vh auto 5vh;
    -ms-grid-rows: 50vh 1fr 5vh;
  }
  .facts-and-figures {
    grid-template-rows: 500px auto auto;
    -ms-grid-rows: 500px 1fr 1fr;
  }
  .office {
    height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 100px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 100px;
    grid-template-rows: 40vh auto 70vh auto auto;
    -ms-grid-rows: 40vh 1fr 70vh 1fr 1fr;
  }
  .retail {
    /* height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    grid-template-rows: 50vh auto auto auto auto; */
  }
}

/*GALLERY LAYOUTS*/

.gallery {
  display: grid;
  grid-template-columns: auto 107.4vh auto;
  -ms-grid-columns: 1fr 107.4vh 1fr;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 6;
  -ms-grid-column-span: 5;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 10px black solid;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .gallery {
    width: 100%;
    display: -ms-flexbox;
    -ms-flex-direction: row;
    flex: 1;
    -ms-flexbox: 0 1 auto;
  }
  .next, .prev {
    display: none;
  }
}

.gallery-wide {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto 89vh auto;
  -ms-grid-columns: 1fr 89vh 1fr;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 6;
  -ms-grid-column-span: 5;
  grid-row-start: 2;
  -ms-grid-row: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 10px black solid;
}

/* temporarily remove office gallery arrows until we have more images */

#next2, #prev2 {
  display: none;
}

.gallery div {
  position: relative;
}

.gallery-wide div {
  position: relative;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .gallery {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 10% auto 10%;
    -ms-grid-columns: 10% 1fr 10%;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
  }
  .office .gallery {
    border-bottom: none;
  }

  .location .gallery {
    border-bottom: none;
  }

  .gallery-b {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    grid-row-end: 3;
    -ms-grid-row-span: 1;
    border-bottom: 10px black solid;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .gallery {
    border-bottom: 5px black solid;
  }
  .gallery-b {
    border-bottom: 5px black solid;
  }
}

/*GRID ITEMS*/

/*INTRO*/

.wrapper {
  z-index: 3;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 6;
  -ms-grid-column-span: 5;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 4;
  -ms-grid-row-span: 3;
  border-bottom: 10px black solid;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .wrapper {
    display: none;
  }
}

/*OVERVIEW*/

.item-b {
  padding-top: 7vh;
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 3;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
}

.item-b2 {
  padding: 15%;
  padding-left: 7vw;
  padding-top: 0;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 4;
  -ms-grid-row-span: 2;
  border-bottom: 10px black solid;
}

.item-c {
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 4;
  -ms-grid-row-span: 3;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/houstonalley-larger.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  border-left: 10px black solid;
  border-bottom: 10px black solid;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .item-b {
    padding-top: 3vh;
    padding-right: 10%;
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 6;
    -ms-grid-column-span: 4;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
  }

  .item-b2 {
    padding-left: 100px;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 6;
    -ms-grid-column-span: 5;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 4;
    -ms-grid-row-span: 2;
    border-bottom: 10px black solid;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-b2 {
    padding: 10% 5%;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 6;
    -ms-grid-column-span: 5;
    padding-left: 50px;
    border-bottom: 5px black solid;
  }
}

@media (max-width: 830px) and (orientation: landscape) {
  .item-b2 {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 6;
    -ms-grid-column-span: 5;
    padding-top: 0;
    padding-left: 50px;
    border-bottom: 5px black solid;
  }
}

/*ARCHITECTURE*/

.item-d {
  padding: 5%;
  padding-left: 5vw;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 2;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
}

.item-d2 {
  padding: 5vw;
  padding-top: 10%;
  padding-bottom: 0;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 2;
  -ms-grid-column-span: 1;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 4;
  -ms-grid-row-span: 2;
  border-bottom: 10px black solid;
  padding-bottom: 0;
}

#item-e {
  padding-top: 4vh;
  padding-left: 7%;
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 3;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 2;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/roger-ferris.jpg");
  background-size: cover;
  background-position: right;
  border-bottom: 10px solid black;
}

#item-e2 {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 4;
  -ms-grid-row-span: 2;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-column-span: 2;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/RBS_EEC.jpg");
  background-size: cover;
  background-position: center;
  border-top: 10px solid black;
  border-left: 10px solid black;
  border-bottom: 10px solid black;
}

#item-e3 {
  grid-column-start: 4;
  -ms-grid-column: 4;
  grid-column-end: 6;
  -ms-grid-column-span: 2;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/ToppingRoseHouse_EEC.jpg");
  background-size: cover;
  border-top: 10px solid black;
  border-left: 10px solid black;
  border-bottom: 10px solid black;
}

#item-e4 {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 3;
  -ms-grid-column-span: 1;
  grid-row-start: 2;
  -ms-grid-row: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 2;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/ScreenlandLofts_EEC.jpg");
  background-size: cover;
  background-position: right;
  border-left: 10px solid black;
}

#item-e5 {
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 6;
  -ms-grid-column-span: 3;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/TheBridge_EEC.jpg");
  background-size: cover;
  border-left: 10px solid black;
}

.item-f {
  padding: 5%;
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 6;
  -ms-grid-column-span: 3;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  border-left: 10px black solid;
  border-bottom: 10px black solid;
}


@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {

    .architecture .right {
      display: none;
    }

    .item-d {
      padding: 5%;
      padding-left: 5vw;
      -ms-grid-column: 1;
      grid-column-start: 1;
      grid-column-end: 4;
      -ms-grid-column-span: 3;
      grid-row-start: 1;
      -ms-grid-row: 1;
      grid-row-end: 2;
      -ms-grid-row-span: 1;
      border-bottom: 10px solid black;
    }

    .item-d2 {
      padding: 5%;
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 4;
      -ms-grid-column-span: 3;
      grid-row-start: 3;
      -ms-grid-row: 3;
      grid-row-end: 4;
      -ms-grid-row-span: 1;
      border-bottom: none;
    }

    #item-e {
      padding-top: 4vh;
      padding-left: 7%;
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 4;
      -ms-grid-column-span: 3;
      grid-row-start: 2;
      -ms-grid-row: 2;
      grid-row-end: 3;
      -ms-grid-row-span: 1;
      border-left: none;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/roger-ferris.jpg");
      background-size: cover;
      background-position: right;
      border-bottom: 10px solid black;
    }

    #item-e2 {
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 3;
      -ms-grid-column-span: 2;
      grid-row-start: 5;
      -ms-grid-row: 5;
      grid-row-end: 6;
      -ms-grid-row-span: 1;
      border-left: 10px black solid;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/RBS_EEC.jpg");
      background-size: cover;
      background-position: center;
      border-top: none;
      border-left: none;
      border-bottom: 10px solid black;
    }

    #item-e3 {
      grid-column-start: 3;
      -ms-grid-column: 3;
      grid-column-end: 4;
      -ms-grid-column-span: 1;
      grid-row-start: 5;
      -ms-grid-row: 5;
      grid-row-end: 6;
      -ms-grid-row-span: 1;
      border-left: 10px black solid;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/ToppingRoseHouse_EEC.jpg");
      background-size: cover;
      background-position: center;
      border-top: none;
      border-left: 10px solid black;
      border-bottom: 10px solid black;
    }

    #item-e4 {
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 2;
      -ms-grid-column-span: 1;
      grid-row-start: 6;
      -ms-grid-row: 6;
      grid-row-end: 7;
      -ms-grid-row-span: 1;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/ScreenlandLofts_EEC.jpg");
      background-size: cover;
      background-position: center;
      border-left: none;
      border-bottom: 10px solid black;
    }

    #item-e5 {
      grid-column-start: 2;
      -ms-grid-column: 2;
      grid-column-end: 4;
      -ms-grid-column-span: 2;
      grid-row-start: 6;
      -ms-grid-row: 6;
      grid-row-end: 7;
      -ms-grid-row-span: 1;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/TheBridge_EEC.jpg");
      background-size: cover;
      background-position: center;
      border-left: 10px solid black;
      border-bottom: 10px solid black;
    }

    .item-f {
      padding: 5%;
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 4;
      -ms-grid-column-span: 3;
      grid-row-start: 4;
      -ms-grid-row: 5;
      grid-row-end: 5;
      -ms-grid-row-span: 1;
      border-left: none;
      border-bottom: 10px black solid;
    }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-d {
    border-bottom: 5px solid black;
  }
  #item-e {
    border-bottom: 5px solid black;
  }
  .item-f {
    border-bottom: 5px solid black;
  }
  #item-e2 {
    border-bottom: 5px solid black;
  }
  #item-e3 {
    border-bottom: 5px solid black;
    border-left: 5px solid black;
  }
  #item-e4 {
    border-bottom: 5px solid black;
  }
  #item-e5 {
    border-bottom: 5px solid black;
    border-left: 5px solid black;
  }
}

@media (max-width: 400px) {
  .video-div {
    height: 130px;
    width: 225px;
  }
}

@media only screen and (max-width: 830px) and (orientation: landscape) {
  .architecture {
    /* height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 10vw;
    grid-template-rows: 50vh auto auto auto; */
  }
}

/*RETAIL*/

.item-g {
  padding-top: 5%;
  padding-left: 3vw;
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 5;
  -ms-grid-column-span: 3;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
}

.item-g3 {
  padding: 4vh;
  padding-left: 7%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
}

.item-f2 {
  margin-top: 10%;
  padding-left: 12%;
  padding-right: 12%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 3;
  -ms-grid-row: 1;
  grid-row-end: 4;
  -ms-grid-row-span: 3;
  border-bottom: 10px black solid;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .item-g3 {
    padding: 3vh;
    padding-left: 0;
    grid-column-start: 2;
    -ms-grid-column: 3;
    grid-column-end: 7;
    -ms-grid-column-span: 4;
  }

  #retail .item-g {
    padding: 0;
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 6;
    -ms-grid-column-span: 4;
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row-end: 4;
    -ms-grid-row-span: 1;
  }

  #retail .item-d2 {
    padding: 5%;
    padding-bottom: 0;
    margin: 0;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 4;
    -ms-grid-row: 4;
    grid-row-end: 5;
    -ms-grid-row-span: 1;
    border-bottom: 0;
  }

  .item-f2 {
    margin-top: 0;
    padding: 5%;
    padding-top: 0;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 5;
    -ms-grid-row: 5;
    grid-row-end: 6;
    -ms-grid-row-span: 1;
    border-bottom: 10px black solid;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-f2 {
    border-bottom: 5px black solid;
  }
}

/*FACTS & FIGURES */

.item-j {
  padding: 0;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 2;
  border-bottom: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/katz.jpg");
  background-size: cover;
  background-position: bottom;
}

.item-l {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 2;
  border-left: 10px black solid;
  border-bottom: 10px black solid;
}

/* .item-m {
  padding: 5% 10%;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  border-bottom: 10px black solid;
} */

.figures {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  width: 80%;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .item-m {
    border-bottom: 10px black solid;
  }

  .item-l {
    padding-left: 5vh;
    border-left: none;
    border-bottom: 10px black solid;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 4;
    -ms-grid-row-span: 2;
  }

  .item-j {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
    border-bottom: 10px black solid;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-m {
    border-bottom: 5px black solid;
  }

  .item-l {
    border-bottom: 5px black solid;
  }

  .item-j {
    border-bottom: 5px black solid;
  }
}

/*OFFICE*/

.item-n {
  padding: 0% 10%;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
}

.item-o {
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  border-left: 10px black solid;
}

.item-n2 {
  padding: 0% 10%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  border-left: 10px black solid;
}

.item-o2 {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
}

#office-pic {
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/office-chair.jpg");
  background-size: cover;
  background-position: center;
}

#retail-pic {
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/Retail-Rendering-cropped.jpg");
  background-size: cover;
  background-position: bottom;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .office .item-g {
    padding-top: 3vh;
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 6;
    -ms-grid-column-span: 4;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
  }

  .item-n {
    padding: 5%;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 6;
    -ms-grid-column-span: 5;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
    border-bottom: none;
    border-right: 10px solid black;
  }

  .item-o {
    padding: 5%;
    padding-top: 0;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 3;
    -ms-grid-row-span: 1;
    border-top: 10px black solid;
    border-bottom: 10px black solid;
    border-left: none;
  }

  .item-n2 {
    padding: 5%;
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 7;
    grid-column-end: 5;
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row-end: 4;
    -ms-grid-row-span: 1;
    border-bottom: none;
    border-left: 10px black solid;
  }

  .item-o2 {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 4;
    -ms-grid-row: 4;
    grid-row-end: 5;
    -ms-grid-row-span: 1;
    border-top: 10px black solid;
    border-bottom: 10px black solid;
  }

  .office .item-g2 {
    padding: 0;
    padding-bottom: 10%;
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 6;
    -ms-grid-column-span: 4;
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row-end: 4;
    -ms-grid-row-span: 1;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-o {
    border-top: 5px black solid;
    border-bottom: 5px black solid;
  }
  .item-n {
    border-right: 5px solid black;
    padding: 10%;
  }
  .item-n2 {
    border-left: 5px solid black;
    padding: 10%;
  }
  .item-o2 {
    border-top: 5px black solid;
    border-bottom: 5px solid black;
  }
}

/*CONTACT*/

.item-q {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 4;
  -ms-grid-row-span: 3;
  border-right: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/houstonalley-larger.jpg");
  background-size: cover;
  background-position: center;
}

.item-r {
  padding: 7%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-column-span: 1;
  position: relative;
  border-bottom: 10px black solid;
}

.item-r2 {
  padding: 7%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
}

.item-r3 {
  padding: 7%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  position: relative;
}

#contact-button {
  bottom: 0;
}

.contact-list {
  font-family: "Roboto", serif;
  font-size: 1em;
}

.phone {
  font-size: 1.9em;
  font-weight: bold;
}


@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .contact {
    height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 50px;
    grid-template-rows: 40vh 30vh 40vh 40vh;
    -ms-grid-rows: 40vh 30vh 40vh 40vh;
  }
  .item-q {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
    border-bottom: 10px black solid;
    border-right: none;
  }

  .item-r {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 3;
    -ms-grid-row-span: 1;
    position: relative;
    border-left: none;
    border-bottom: 10px black solid;
  }

  .item-r2 {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row-end: 4;
    -ms-grid-row-span: 1;
    position: relative;
    border-left: none;
    border-bottom: 10px black solid;
  }

  .item-r3 {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 4;
    -ms-grid-row: 4;
    grid-row-end: 5;
    -ms-grid-row-span: 1;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-q {
    border-bottom: 5px black solid;
  }

  .item-r {
    border-left: none;
    border-bottom: 5px black solid;
  }

  .item-r2 {
    border-bottom: 5px black solid;
  }

  .item-r3 .parent {
    width: 100%;
  }
  .phone {
    font-size: 1.4em;
    font-weight: bold;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  .contact {
    height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 50px;
    grid-template-rows: 70vh auto auto;
    -ms-grid-rows: 70vh 1fr 1fr;
  }
}


/*TEAM*/

.item-t {
  padding-top: 3vh;
  padding-left: 7%;
  grid-column-start: 4;
  -ms-grid-column: 4;
  grid-column-end: 6;
  -ms-grid-column-span: 2;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  border-left: 10px black solid;
  border-bottom: 10px black solid;
}

.item-u {
  padding-top: 4vh;
  padding-left: 7%;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 4;
  -ms-grid-column-span: 3;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/east-end.jpg");
  background-size: cover;
}

.item-v {
  padding: 5vh;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 4;
  -ms-grid-column-span: 3;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
}

#item-w {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 3;
  -ms-grid-column-span: 2;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  border-right: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/theplant.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

#item-x {
  grid-column-start: 3;
  -ms-grid-column: 3;
  grid-column-end: 5;
  -ms-grid-column-span: 2;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  border-right: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/123william.png");
  background-size: cover;
  background-position: bottom;
  position: relative;
}

#item-y {
  grid-column-start: 4;
  -ms-grid-column: 4;
  grid-column-end: 6;
  -ms-grid-column-span: 2;
  grid-row-start: 2;
  -ms-grid-row: 2;
  grid-row-end: 3;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/285madison.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

#item-z {
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/36east20th.png");
  background-size: cover;
  background-position: center;
  position: relative;
}

.contact-box {
  cursor: pointer;
  position: relative;
}

.caption {
  position: absolute;
  background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
  background: rgba(204, 204, 204, 0.35);  bottom: 0;
  left: 0;
  padding:3px 7px;
}

.caption a {
  font-size: .8em;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

.caption a:hover {
  font-family: "Roboto", sans-serif;
  text-decoration: underline;
  color: black;
}


@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {

    .team .right {
      display: none;
    }

    .item-t {
      padding: 5%;
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 7;
      -ms-grid-column-span: 6;
      grid-row-start: 1;
      -ms-grid-row: 1;
      grid-row-end: 2;
      -ms-grid-row-span: 1;
      border-left: none;
      border-bottom: 10px black solid;
    }

    .item-u {
      padding-top: 4vh;
      padding-left: 7%;
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 7;
      -ms-grid-column-span: 6;
      grid-row-start: 2;
      -ms-grid-row: 2;
      grid-row-end: 3;
      -ms-grid-row-span: 1;
      border-bottom: 10px black solid;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/east-end.jpg");
      background-size: cover;
    }

    .item-v {
      padding: 5vh;
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 7;
      -ms-grid-column-span: 6;
      grid-row-start: 3;
      -ms-grid-row: 3;
      grid-row-end: 4;
      -ms-grid-row-span: 1;
      border-bottom: 10px black solid;
    }

    #item-w {
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 3;
      -ms-grid-column-span: 2;
      grid-row-start: 5;
      -ms-grid-row: 5;
      grid-row-end: 6;
      -ms-grid-row-span: 1;
      border-bottom: 10px black solid;
      border-right: 10px black solid;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/theplant.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
    }

    #item-x {
      grid-column-start: 3;
      -ms-grid-column: 3;
      grid-column-end: 7;
      -ms-grid-column-span: 4;
      grid-row-start: 5;
      -ms-grid-row: 5;
      grid-row-end: 6;
      -ms-grid-row-span: 1;
      border-bottom: 10px black solid;
      border-right: none;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/123william.png");
      background-size: cover;
      background-position: bottom;
      position: relative;
    }

    #item-y {
      grid-column-start: 1;
      -ms-grid-column: 1;
      grid-column-end: 4;
      -ms-grid-column-span: 3;
      grid-row-start: 4;
      -ms-grid-row: 4;
      grid-row-end: 5;
      -ms-grid-row-span: 1;
      border-bottom: 10px black solid;
      border-right: 10px black solid;
      border-left: none;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/285madison.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
    }

    #item-z {
      grid-column-start: 4;
      -ms-grid-column: 4;
      grid-column-end: 7;
      -ms-grid-column-span: 3;
      grid-row-start: 4;
      -ms-grid-row: 4;
      grid-row-end: 5;
      -ms-grid-row-span: 1;
      border-bottom: 10px black solid;
      background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/36east20th.png");
      background-size: cover;
      background-position: center;
      position: relative;
    }

}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .item-t {
    border-bottom: 5px solid black;
  }
  .item-u {
    border-bottom: 5px solid black;
  }
  .item-v {
    border-bottom: 5px solid black;
  }
  #item-y {
    border-bottom: 5px solid black;
    border-right: 5px solid black;
  }
  #item-z {
    border-bottom: 5px solid black;
  }
  #item-w {
    border-bottom: 5px solid black;
    border-right: 5px solid black;
  }
  #item-x {
    border-bottom: 5px solid black;
  }
}


/*FOOTER*/

.footer-flex {
  display: flex;
  flex-direction: row;
  text-align: bottom;
  color: #d3d3d3;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 0.7rem;
  width: 70%;
}

.footer-flex-child {
  width: 30%;
}

.footer-flex a {
  color: #d3d3d3;
  font-size: 0.7rem;
}

/* mobile portrait */
@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .footer-flex {
    width: 100%;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .footer-flex {
    flex-direction: column;
    margin-bottom: 5%;
  }
  .footer-flex-child {
    width: 100%;
    margin: 1% 0;
  }
}
