.bInfoBox {
  font-family: "Roboto", sans-serif;
  cursor: default;
  background: #f37673;
  width: 120px;
  max-width: 120px;
  height: auto;
  padding: 12px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  box-shadow: 0 1px 5px #999;
  visibility: visible;
  z-index: 0;
  pointer-events: none;
}

/*LOCATION*/

.location {
  /* margin-top: -1px; */
  height: auto;
  grid-template-columns: 7vw 15vw 10vw 10vw auto 100px;
  -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 100px;
  grid-template-rows: 25vh 75vh 100vh 40vh;
  -ms-grid-rows: 25vh 90vh 100vh 40vh;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .location {
    margin-top: 0;
    height: auto;
    grid-template-columns: 100px 15vw 10vw 10vw auto 100px;
    -ms-grid-columns: 100px 15vw 10vw 10vw 1fr 100px;
    grid-template-rows: auto auto 120vh 50vh 50vh;
    -ms-grid-rows: 1fr 1fr 120vh 50vh 50vh;
  }
  .location .right-long {
    display: none;
  }

  .location .item-g2 {
    display: none;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .location {
    margin-top: 0;
    height: auto;
    grid-template-columns: 50px 15vw 10vw 10vw auto 50px;
    -ms-grid-columns: 50px 15vw 10vw 10vw 1fr 50px;
    grid-template-rows: auto auto 160vh 300px 300px;
    -ms-grid-rows: 1fr 1fr 160vh 300px 300px;
  }
}

@media (max-width: 830px) and (orientation: landscape) {
  .location {
    margin-top: 0;
    height: auto;
    grid-template-rows: 50vh auto 240vh 300px 300px;
    -ms-grid-rows: 50vh 1fr 240vh 300px 300px;
  }
}

.location .item-g {
  padding-top: 3vh;
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 4;
  -ms-grid-column-span: 2;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
}

.item-g2 {
  padding: 10%;
  padding-top: 8vh;
  padding-left: 7%;
  padding-right: 7vw;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-row-column: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
}

.item-h {
  padding-top: 20%;
  padding-bottom: 20%;
  padding-left: 10%;
  padding-right: 10%;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 5;
  -ms-grid-column-span: 4;
  grid-row-start: 4;
  -ms-grid-row: 4;
  grid-row-end: 5;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/subway.jpg");
  background-size: cover;
  background-position: bottom;
  -ms-grid-column-span: 4;
  -ms-grid-row-span: 1;
}

.item-i {
  padding: 10%;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-row-span: 1;
  grid-row-start: 4;
  -ms-grid-row: 4;
  grid-row-end: 5;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  border-left: 10px black solid;
  background-image: url("https://s3.amazonaws.com/reacdn/141ehouston/running.jpg");
  background-size: cover;
  background-position: bottom;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .left {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 2;
    -ms-grid-column-span: 1;
    grid-row-start: 4;
    -ms-grid-row: 4;
    grid-row-end: 6;
    -ms-grid-row-span: 2;
    border-bottom: 10px black solid;
  }
  .item-h {
    padding: 10%;
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column-end: 7;
    -ms-grid-column-span: 5;
    grid-row-start: 4;
    -ms-grid-row: 4;
    grid-row-end: 5;
    -ms-grid-row-span: 1;
    border-left: 10px black solid;
    border-bottom: 10px black solid;
  }

  .item-i {
    grid-column-start: 2;
    grid-column-end: 7;
    -ms-grid-column: 2;
    grid-row-start: 5;
    -ms-grid-column-span: 5;
    grid-row-end: 6;
    -ms-grid-row-span: 1;
    border-left: 10px black solid;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  .left {
    border-bottom: 5px black solid;
  }
  .item-h {
    border-left: 5px black solid;
    border-bottom: 5px black solid;
  }

  .item-i {
    border-bottom: 5px black solid;
    border-left: 5px black solid;
  }
}

/*MAP & TRANSPORTATION*/

.map-div {
  margin-right: 0;
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 6;
  -ms-grid-column-span: 5;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 60% 40%;
  -ms-grid-columns: 60% 40%;
  grid-template-rows: 35vh 10vh auto;
  -ms-grid-rows: 35vh 10vh 1fr;
}

#map {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: 2;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 4;
  -ms-grid-row-span: 3;
  border-right: 10px black solid;
  height: 100%;
  width: 100%;
  position: relative;
}

.transpo-header {
  padding-top: 10%;
  padding-left: 10%;
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 3;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  border-bottom: 10px black solid;
}

.transpo-links {
  padding-left: 10%;
  padding-right: 15%;
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-column-end: 3;
  -ms-grid-column-span: 1;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
}

.map-list {
  list-style: none;
  font-family: "Roboto", serif;
  font-weight: 300;
  line-height: 1.2rem;
}

.transpo-links p {
  cursor: pointer;
}

#rest-title {
  color: #0F2151;
}

#cafe-title {
  color: #497CA0;
}

#hotel-title {
  color: #F1705E;
}

#retail-title {
  color: #EDBA37;
}

#attraction-title {
  color: #44BA7E;
}

.marker-list {
  display: none;
}

.displayed {
  display: block;
  transition: all 1s ease-in;
  transition-duration: all;
}

.transpo-links p {
  font-family: "Frank Ruhl Libre", serif;
  font-style: italic;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  #map {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 3;
    -ms-grid-column-span: 2;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
    border-right: none;
    border-bottom: 10px black solid;
  }

  .map-div {
    margin-right: 0;
    margin-top: 20px;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row-end: 4;
    -ms-grid-row-span: 1;
    border-top: 10px black solid;
    border-bottom: 10px black solid;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 40% 60%;
    -ms-grid-columns: 40% 60%;
    grid-template-rows: 60% auto;
    -ms-grid-rows: 60% 1fr;
  }

  .transpo-header {
    display: none;
  }

  .transpo-details {
    display: none;
  }

  .transpo-links {
    display: block;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 2;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 3;
    -ms-grid-row-span: 1;
    padding: 5% 10%;
  }

  #transportation {
    display: block;
    padding: 0 3% 0 3%;
  }

  .marker-list p {
    font-size: 1em;
  }

  .marker-list li {
    font-size: 1em;
  }

  .title {
    font-family: "Roboto", sans-serif;
    font-size: 1.2em;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  #map {
    border-bottom: 5px black solid;
  }
  .map-div {
    border-top: 5px black solid;
    border-bottom: 5px black solid;
    grid-template-rows: 50% auto;
    -ms-grid-rows: 50% 1fr;
  }
}

@media (max-width: 830px) and (orientation: landscape) {
  .title {
    font-family: "Roboto", sans-serif;
    font-size: 1.2em;
  }
  .marker-list li {
    font-size: 1em;
  }
  .marker-list p {
    font-size: 1em;
  }
  .map-div {
    grid-template-rows: 40% auto;
    -ms-grid-rows: 40% 1fr;
  }
}


/*Map Styles */

.gm-style .gm-style-iw {
   background-color: white;
   font-family: "Frank Ruhl Libre", serif;
   font-style: italic;
   font-size: 14px;
}

.gm-style .gm-style-iw div {
  padding-right: 1px;
}
