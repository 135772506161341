* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

*:focus {
  outline: none !important;
}

body {
  font-size: 1em;
  margin: 0;
  font-weight: 500;
  font-family: "Frank Ruhl Libre", serif;
  overflow-x: hidden;
}

h1 {
  font-size: 3.1em;
  display: block;
  color: black;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  line-height: 1em;
  opacity: 1;
}

h3 {
  font-size: 1.5em;
  display: block;
  color: black;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

h4 {
  display: block;
  color: black;
  font-style: italic;
  font-weight: 500;
  font-family: "Frank Ruhl Libre", serif;
  opacity: 1;
}

h6 {
  font-size: 2em;
  display: block;
  color: black;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 1em;
}

h7 {
  font-size: 1.4em;
  color: black;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  bottom: 20px;
}

h8 {
  font-size: 2.5em;
  color: black;
  /* font-weight: lighter; */
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}

p {
  font-size: 1.2em;
  font-weight: 500;
  color: black;
}

a {
  font-weight: 700;
  color: black;
  position: relative;
  font-family: "Roboto", sans-serif;
}

a:hover {
  color: gray;
}

ul {
  font-size: 0.9em;
  padding: 0;
  list-style-type: none;
}

li {
  word-wrap: break-word;
}

footer {
  padding: 2.5%;
  margin: 0;
  background-color: black;
}

footer p {
  color: #d3d3d3;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.2vh;
}

ul.dashed {
  /* font-family: "Roboto";
  font-weight: 400; */
  font-size: 1.2em;
  list-style: none;
  margin-left: 1em;
  padding-left: 1em;
  line-height: 1.5em;
}
ul.dashed > li {
  text-indent: -2px;
}
ul.dashed > li:before {
  display: inline-block;
  content: url("../images/bullet.svg");
  /* width: 1em; */
  margin-left: -1.7em;
  top: 0;
  bottom: 0;
}

@media screen and (max-width: 1200px) , screen and (max-height: 1050px) {
  body {
    font-size: .9em;
  }

  h1 {
    font-size: 2.6em;
  }

  h3 {
    font-size: 1.4em;
  }

  h4 {
    display: block;
    color: black;
    font-style: italic;
    font-weight: 500;
    font-family: "Frank Ruhl Libre", serif;
    opacity: 1;
  }

  h6 {
    font-size: 2em;
    display: block;
    color: black;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  h7 {
    font-size: 1.4em;
    color: black;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    bottom: 20px;
  }

  h8 {
    font-size: 2.5em;
    color: black;
    /* font-weight: lighter; */
    font-family: "Roboto", sans-serif;
    margin-bottom: 0;
  }

  p {
    font-size: 1em;
    font-weight: 500;
    color: black;
  }

  a {
    font-weight: 700;
    color: black;
    position: relative;
    font-family: "Roboto", sans-serif;
  }

  a:hover {
    color: gray;
  }

  ul {
    font-size: 1em;
    padding: 0;
    list-style-type: none;
  }

  li {
    word-wrap: break-word;
  }

  footer p {
    color: #d3d3d3;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.2vh;
  }

  ul.dashed {
    /* font-family: "Roboto";
    font-weight: 400; */
    font-size: 1em;
    list-style: none;
    margin-left: 1em;
    padding-left: 1em;
    line-height: 1.5em;
  }
  ul.dashed > li {
    text-indent: -2px;
  }
  ul.dashed > li:before {
      display: inline-block;
      content: url("../images/bullet.svg");
      /* width: 1em; */
      margin-left: -1.7em;
      top: 0;
      bottom: 0;
  }

}


/* ipad & mobile portrait */
@media (max-width: 900px), (max-width: 1050px) and (orientation: portrait) {
  p {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;
    margin-top: 3%;
    margin-bottom: 4%;
  }

  h4 {
    font-size: 0.8em;
    margin-bottom: 0;
  }

  h6 {
    margin: 0;
  }
  h7 {
    font-size: 3em;
    margin-bottom: 0;
  }
  h8 {
    font-size: 4em;
  }

  form {
    margin-top: 5%;
  }
}

/*BUTTONS*/

.button-parent {
  margin: auto;
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.button-one {
  margin-top: 0;
  width: 140px;
  height: 32px;
  background-color: white;
  border: 1px solid black;
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  color: black;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.button {
  background-color: white;
  border: 1px solid black;
  font-family: "Roboto", sans-serif;
  font-size: .8rem;
  color: black;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.button:hover {
  color: gray;
}

.button-one:hover {
  color: gray;
}

.button-parent-grid {
  margin-top: 0%;
  margin: auto;
  width: 200px;
  height: 2rem;
  display: flex;
  flex-direction: row;
  text-align: bottom;
}

.box {
  width: 47%;
  height: 130px;
  padding-right: 3%;
  position: relative;
  word-wrap: break-word;
  font-size: .9rem;
}

.visit {
  position: absolute;
  bottom: 0;
}

.visit img {
  height: .9rem;
  margin-bottom: -2px;
  margin-left: 3px;
}

.box svg {
  height: 75%;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.large-box {
  width: 87%;
  text-align: bottom;
  position: relative;
  margin-right: 3%;
}

.small-box {
  width: 10%;
  text-align: bottom;
  position: relative;
}

.item-b2 button {
  margin-top: 15%;
}

@media (max-width: 900px) and (orientation: landscape),
  (max-width: 1050px) and (orientation: portrait) {
  .button-parent {
    width: 30vw;
    height: 2rem;
  }

  .button {
    font-size: 0.7rem;
  }

  .item-b2 button {
    margin-top: 3%;
  }

  .button-one {
    width: 30vw;
    height: 2rem;
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 600px) {
  .button-parent {
    width: 55vw;
    height: 2rem;
  }

  .button {
    font-size: 0.7rem;
  }

  .button-one {
    width: 55vw;
    height: 2rem;
    font-size: 0.7rem;
  }
}

/*BACK TO TOP BUTTON*/

#myBtn {
  width: 55px;
  position: fixed;
  bottom: 2vh;
  left: calc(100vw - 100px + 19px);
  z-index: 97;
}

#myBtn:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

@media (max-width: 900px), (max-width: 1050px) and (orientation: portrait) {
  #myBtn {
    display: none;
  }
}

@media only screen and (max-width: 830px) and (orientation: landscape) {
  #myBtn {
    display: none;
  }
}

/*SVG*/

.down-arrow {
  position: absolute;
  left: 0;
  width: 1.8vw;
}

.prev {
  position: absolute;
  top: 0;
  bottom: 0;
  /* left: 0; */
  right: 20%;
  margin: auto;
  cursor: pointer;
}

.next {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  /* right: 0; */
  margin: auto;
  cursor: pointer;
}

/* .gallery .prev {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20%;
  margin: auto;
  cursor: pointer;
}

.gallery .next {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  margin: auto;
  cursor: pointer;
} */


/*IMAGE*/

.corporate-logo {
  height: 110px;
  display: block;
  /* margin: auto; */
}

#building-img {
  width: 80%;
}

#shopping-img {
  padding-top: 5%;
  width: 80%;
}

#subway-img {
  width: 100%;
}

#path-img {
  width: 80%;
  margin-top: 60%;
}

#views-img {
  width: 80%;
}

#workout-img {
  width: 90%;
  margin-top: 20%;
}

#deck-img {
  margin-top: 20%;
  width: 100%;
}

@media (max-width: 900px), (max-width: 1050px) and (orientation: portrait) {
  #building-img {
    width: 80%;
    padding-top: 10%;
    margin: auto;
  }

  #path-img {
    width: 100%;
    margin-top: 5vh;
  }

  #deck-img {
    width: 100%;
    margin-top: 5vh;
  }

  #workout-img {
    width: 100%;
    margin-top: 5vh;
  }

  #shopping-img {
    width: 100%;
    margin-top: 5vh;
  }

  #views-img {
    width: 100%;
    margin-top: 5vh;
  }

  .small-img {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
