/*HAMBURGER MENU*/

.bg-cover {
  z-index: 90;
  position: fixed;
  visibility: hidden;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  opacity: 1;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 7vw 15vw 10vw 10vw auto 15vw 100px;
  -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 15vw 100px;
  grid-template-rows: 90px 15vh auto;
  -ms-grid-rows: 90px 15vh 1fr;
  transform: translateX(calc(100%));
  transition: transform 0.5s ease-in-out;
}

.bg-menu {
  position: relative;
  z-index: 99;
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 6;
  -ms-grid-column-span: 1;
  grid-row-start: 3;
  -ms-grid-row: 3;
  grid-row-end: 4;
  -ms-grid-row-span: 1;
  background-color: white;
  opacity: 1;
  border-left: 10px black solid;
  border-right: 10px black solid;
}

.bg-b {
  grid-column-start: 7;
  -ms-grid-column: 7;
  grid-column-end: 8;
  -ms-grid-column-span: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 2;
  -ms-grid-row-span: 1;
  background-color: white;
  opacity: 1;
  border-bottom: 10px black solid;
  border-top: 10px black solid;
  border-left: 10px black solid;
}

.bg-c {
  grid-column-start: 5;
  -ms-grid-column: 5;
  grid-column-end: 7;
  -ms-grid-column-span: 2;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: 3;
  -ms-grid-row-span: 2;
  background-color: transparent;
  opacity: 1;
  border-left: 10px black solid;
  border-bottom: 10px black solid;
  border-top: 10px black solid;
}

.reveal {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.hamburger-shell {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  margin: 0;
  position: fixed;
  width: 100px;
  height: 100px;
  overflow: auto;
  left: calc(100vw - 100px + 33.33px);
  top: 15px;
}

.top,
.middle,
.bottom {
  position: absolute;
  width: 33%;
  height: 2px;
  background-color: black;
  transition: all 350ms ease-in-out;
  top: 20px;
}

.middle {
  top: 28px;
}

.bottom {
  top: 36px;
}

#menu {
  position: absolute;
  z-index: 99;
  color: black;
  display: none;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 2.5rem;
  padding-left: 1em;
  width: 100%;
  text-align: left;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #menu {
    font-size: 1.5rem;
  }
}

#menu a li {
  text-decoration: none !important;
  padding-bottom: 3%;
  list-style: none;
}

#menu a li:hover {
  color: gray;
  padding-right: 3px;
}

#menu a li:hover:after {
}

#menu a {
  text-decoration: none;
}

.rotate {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 30px;
  background-color: black;
  height: 2px;
}

.rotate-back {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  top: 30px;
  background-color: black;
  height: 2px;
}

.top {
  -webkit-animation-delay: 100ms;
  cursor: pointer;
}

.middle {
  -webkit-animation-delay: 250ms;
}

.bottom {
  -webkit-animation-delay: 400ms;
}

.bump {
  top: 64px;
  color: red;
}

.hidden {
  display: none;
}

.overlay {
  display: none;
  z-index: 90;
  opacity: 0.5;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
}

.obscure {
  display: block;
}

@media (max-width: 1050px) {
  .bg-cover {
    border-bottom: none;
    grid-template-rows: 100px 50vh auto;
    -ms-grid-rows: 100px 50vh 1fr;
  }
  .bg-menu {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 4;
    -ms-grid-row-span: 2;
    border-left: 10px black solid;
    border-bottom: 10px black solid;
    border-right: none;
    border-top: none;
  }
  .bg-a {
    border-left: 10px black solid;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
  }
  .bg-b {
    grid-column-start: 7;
    -ms-grid-column: 7;
    grid-column-end: 8;
    -ms-grid-column-span: 1;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
    background-color: white;
    opacity: 1;
    border-bottom: 10px black solid;
    border-top: 10px black solid;
    border-left: 10px black solid;
  }
  .bg-c {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row-end: 2;
    -ms-grid-row-span: 1;
    border-left: 10px black solid;
    border-bottom: 10px black solid;
    border-top: 10px black solid;
  }
  .bg-d {
    border-left: 10px black solid;
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row-end: 5;
    -ms-grid-row-span: 3;
  }
}

@media (max-width: 830px) and (orientation: landscape),
  (max-width: 500px) and (orientation: portrait) {
  #menu {
    font-size: 1.4rem;
  }
  .hamburger-shell {
    width: 75px;
    height: 75px;
    left: calc(100vw - 80px + 40px);
    top: 15px;
  }
  .top,
  .middle,
  .bottom {
    top: 5px;
  }
  .middle {
    top: 11px;
  }
  .bottom {
    top: 17px;
  }
  .rotate {
    top: 15px;
  }
  .rotate-back {
    top: 15px;
  }
  .bg-cover {
    grid-template-rows: 60px 50vh auto;
    -ms-grid-rows: 60px 50vh 1fr;
    grid-template-columns: 7vw 15vw 10vw 10vw auto 15vw 50px;
    -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 15vw 50px;
  }
  .bg-menu {
    border-left: 5px black solid;
    border-bottom: 5px black solid;
  }
  .bg-a {
    border-left: 5px black solid;
  }
  .bg-b {
    border-bottom: 5px black solid;
    border-top: 5px black solid;
    border-left: 5px black solid;
  }
  .bg-c {
    border-left: 5px black solid;
    border-bottom: 5px black solid;
    border-top: 5px black solid;
  }
  .bg-d {
    border-left: 5px black solid;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  #menu {
    font-size: 1rem;
  }
  .bg-cover {
    grid-template-rows: 60px auto 50vh;
    -ms-grid-rows: 60px 1fr 50vh;
    grid-template-columns: 7vw 15vw 10vw 10vw auto 15vw 50px;
    -ms-grid-columns: 7vw 15vw 10vw 10vw 1fr 15vw 50px;
  }
  .bg-menu {
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column-end: 7;
    -ms-grid-column-span: 6;
    grid-row-start: 2;
    -ms-grid-row: 3;
    grid-row-end: 5;
    -ms-grid-row-span: 2;
    border-left: 5px black solid;
    border-bottom: 5px black solid;
    border-right: none;
    border-top: none;
  }
  .bg-a {
    display: none;
  }
  .bg-d {
    border-bottom: 5px black solid;
  }
}
